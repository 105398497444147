export const PermissionName = {
    Tenant: "Wapps.Permission.Tenants",
    TenantUpdateInfo: "Wapps.Permission.Tenants.UpdateInfo",

    Wizard: "Wapps.Permission.Wizard",
    WizardDesign: "Wapps.Permission.Wizard.Design",
    WizardDesignQuery: "Wapps.Permission.Wizard.Design.Query",
    WizardDesignUpdate: "Wapps.Permission.Wizard.Design.Update",
    WizardModules: "Wapps.Permission.Wizard.Module",
    WizardModulesQuery: "Wapps.Permission.Wizard.Module.Query",
    WizardModulesCreate: "Wapps.Permission.Wizard.Module.Create",
    WizardModulesUpdate: "Wapps.Permission.Wizard.Module.Update",
    WizardModulesDelete: "Wapps.Permission.Wizard.Module.Delete",

    Appointments: "Wapps.Permission.Appointments",
    AppointmentsQueryMine: "Wapps.Permission.Appointments.Query.Mine",
    AppointmentsQueryAll: "Wapps.Permission.Appointments.Query.All",
    AppointmentsCreate: "Wapps.Permission.Appointments.Create",
    AppointmentsUpdate: "Wapps.Permission.Appointments.Update",
    AppointmentsDelete: "Wapps.Permission.Appointments.Delete",

    Users: "Wapps.Permission.Users",
    UsersQuery: "Wapps.Permission.Users.Query",
    UsersCreate: "Wapps.Permission.Users.Create",
    UsersUpdate: "Wapps.Permission.Users.Update",
    UsersDelete: "Wapps.Permission.Users.Delete",

    Integrations: "Wapps.Permission.Users",
    IntegrationsQuery: "Wapps.Permission.Users.Query",
    IntegrationsCreate: "Wapps.Permission.Users.Create",
    IntegrationsUpdate: "Wapps.Permission.Users.Update",
    IntegrationsDelete: "Wapps.Permission.Users.Delete",

    Prescriptions: "Health.Permission.Prescriptions",
    PrescriptionsMine: "Health.Permission.Prescriptions.Mine",
    PrescriptionsAll: "Health.Permission.Prescriptions.All",
    PrescriptionsCreate: "Health.Permission.Prescriptions.Create",
    PrescriptionsDelete: "Health.Permission.Prescriptions.Delete",

    Exams: "Health.Permission.Exams",
    ExamsMine: "Health.Permission.Exams.Mine",
    ExamsAll: "Health.Permission.Exams.All",
    ExamsCreate: "Health.Permission.Exams.Create",
    ExamsDelete: "Health.Permission.Exams.Delete",

    Doctors: "Health.Permission.Doctors",
    DoctorsQuery: "Health.Permission.Doctors.Query",
    DoctorsCreate: "Health.Permission.Doctors.Create",
    DoctorsUpdate: "Health.Permission.Doctors.Update",
    DoctorsDelete: "Health.Permission.Doctors.Delete",

    Patients: "Health.Permission.Patients",
    PatientsQuery: "Health.Permission.Patients.Query",
    PatientsCreate: "Health.Permission.Patients.Create",
    PatientsUpdate: "Health.Permission.Patients.Update",
    PatientsDelete: "Health.Permission.Patients.Delete",
    PatientsDataExport: "Health.Permission.Patients.DataExport",

    PatientTimeline: "Health.Permission.PatientTimeline",
    PatientTimelineQuery: "Health.Permission.PatientTimeline.Query",
    PatientTimelineCreate: "Health.Permission.PatientTimeline.Create",
    PatientTimelineUpdate: "Health.Permission.PatientTimeline.Update",
    PatientTimelineDelete: "Health.Permission.PatientTimeline.Delete",

    GoogleCalendarIntegration: "Wapps.Permission.GoogleCalendar.Integration",

    Marketing: "Wapps.Permission.Marketing",
    MarketingPushNotification: "Wapps.Permission.Marketing.PushNotification",
    MarketingPushNotificationHistory: "Wapps.Permission.Marketing.PushNotification.History",
    MarketingPushNotificationCreate: "Wapps.Permission.Marketing.PushNotification.Create",

    Report: "Wapps.Permission.Report",
    ReportAppointmentQuery: "Wapps.Permission.Report.Appointment.Query",

    PatientFileManager: "Health.Permission.PatientFileManager",
    PatientFileManagerQuery: "Health.Permission.PatientFileManager.Query",
    PatientFileManagerCreate: "Health.Permission.PatientFileManager.Create",
    PatientFileManagerUpdate: "Health.Permission.PatientFileManager.Update",
    PatientFileManagerDelete: "Health.Permission.PatientFileManager.Delete",
};
