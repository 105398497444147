import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { navigation } from "app/navigation/navigation";
import { AppAuthService } from "@shared/auth/app-auth.service";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { AppSessionService } from "@shared/session/app-session.service";
import { AppComponentBase } from "@shared/app-component-base";
import { TenantLoginInfoDto } from "@shared/service-proxies/service-proxies";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { NotificationService, NotificationType } from "@app/wapps/notification/notification.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Notification } from "@app/wapps/notification/notification.model";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { UsersService } from "@app/wapps/system/users/users.service";
import { SafeUrl } from "@angular/platform-browser";
import { SupportChatService } from "@app/wapps/system/support/support-chat.service";
import { ChatPanelService } from "app/wapps/chat-panel/chat-panel.service";

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent extends AppComponentBase implements OnInit, OnDestroy {
    horizontalNavbar: boolean;

    rightNavbar: boolean;

    hiddenNavbar: boolean;

    languages: any;

    navigation: any;

    selectedLanguage: any;

    userStatusOptions: any[];

    skipCount: number = 0;

    maxResult: number = 5;

    @ViewChild("profileMenu")
    profileMenu: MatMenu;

    @ViewChild("notificationMenu") notificationMenu: MatMenu;

    showItemProfileInfo: boolean = true;

    // Private
    private _unsubscribeAll: Subject<any>;

    public notifications: Notification[] = [];

    countUnread: number = 0;

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    public avatarUrl: string;

    public username: string;

    public fullName: string;

    public logoUrl: SafeUrl;

    supportChatEnabled: boolean;

    chatEnabled: boolean;
    
    public tenantId: number;
    
    tenantClasses: string[] = [];

    constructor(
        private _authService: AppAuthService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _internalChatService: ChatPanelService,
        private _translateService: TranslateService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _sessionService: AppSessionService,
        private _notificationService: NotificationService,
        public _matDialog: MatDialog,
        private _router: Router,
        injector: Injector,
        private _fuseSplashService: FuseSplashScreenService,
        private _usersService: UsersService,
        public _supportChatService: SupportChatService,
    ) {
        super(injector);
        this.username = _sessionService.user != null ? _sessionService.user.name : "";
        this.fullName =
            this.appSession.user != null ? this.appSession.user.name + " " + this.appSession.user.surname : "";
        let avatar = this.appSession.user != null ? this.appSession.user.avatar : undefined;
        this.avatarUrl = !!avatar ? avatar : "assets/images/avatars/profile.jpg";

        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
            },
            {
                id: "pt-BR",
                title: "Portuguese",
                flag: "pt-BR",
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._usersService.onLoggedUserAvatarChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(url => {
            if (url) {
                this.avatarUrl = url;
            }
        });

        this._usersService.onLoggedUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
            if (user) {
                this.username = user.name;
                this.fullName = user.fullName;
            }
        });

        this._sessionService.onTenantInfoChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
            if (this.appSession.tenantInfo && this.appSession.tenantInfo.logoUrl) {
                this.logoUrl = this._domSanitizer.bypassSecurityTrustUrl(this.appSession.tenantInfo.logoUrl);
            } else {
                this.logoUrl = this._domSanitizer.bypassSecurityTrustUrl("assets/images/logos/green.svg");
            }
        });

        this.supportChatEnabled = this._supportChatService.enabled;

        this._internalChatService.chatEnabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatEnabled => {
                this.chatEnabled = chatEnabled;
            });
        
        // console.log('host', location.host);
        // console.log('hostname', location.hostname.replace(/\W/g, ''));
        // console.log('hostname', 'app.e4mind.com.br'.replace(/\W/g, ''));
    }

    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
            this.horizontalNavbar = settings.layout.navbar.position === "top";
            this.rightNavbar = settings.layout.navbar.position === "right";
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
            this.showItemProfileInfo = settings.layout.toolbar.showItemProfileInfo === true;
        });

        //Notifications
        this._notificationService.onRecentNotificationsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: any[]) => {
                this.notifications = notifications;
                this.changeDetector.markForCheck();
            });

        this._notificationService.onBadgeUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((badgeCount: number) => {
                this.countUnread = badgeCount;
            });

        this._notificationService.onNotificationOpened.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: boolean) => {
                if (value) {
                    this.notificationMenu.closed.emit();
                }
            });
        this._sessionService.onSessionChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
            if (!!user) {
                this.username = user != null ? user.name : "";
                this.fullName = user != null ? user.name + " " + user.surname : "";
            }
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
        
        this.tenantId = this._sessionService.tenantId;
        
        this.tenantClasses = [`tenant-${this.tenantId}`]
        
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isCalendar(notification) {
        return NotificationType.Appointment == notification.notification.data.type;
    }

    getDateString(date) {
        return this._notificationService.getDate(new Date(date));
    }

    setReadAll() {
        this._notificationService.setReadAll().then();
    }

    openNotification(event, notification) {
        this._notificationService.handleOpenNotification(notification);
        this.trigger.closeMenu();
    }

    markAsRead(event, id) {
        event.stopPropagation();
        this._notificationService.setRead(id).then();
    }

    markAllNotificationsAsRead() {
        this._notificationService.setReadAll().then();
    }

    showAllNotifications() {
        this._router.navigate(["notification"]).then();
        this.notificationMenu.closed.emit();
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
        this.profileMenu.closed.emit();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        this.profileMenu.closed.emit();
        this._fuseProgressBarService.show();
        this._authService.logout(true);
    }

    navigateToProfilePage() {
        this.profileMenu.closed.emit();
    }

    navigateToNotificationSettings() {
        this._router.navigate(["notification/settings"]).then();
        this.notificationMenu.closed.emit();
    }

    changeTenant(info: TenantLoginInfoDto) {
        this.profileMenu.closed.emit();
        this._fuseSplashService.show();
        this.appSession.changeTenant(info).then(() => {
            this._fuseSplashService.hide();
        });
    }

    openLegalDocuments() {
        this._router.navigate(["legal"]).then();
        this.profileMenu.closed.emit();
    }

    isLocked() {
        return this._sessionService.isLocked();
    }

    openKb() {
        window.open("https://help.vsaude.com.br", "_blank");
    }

    openCanpaign() {
        window.open("https://vsaude.com.br/performance/euindicovsaude/", "_blank");
    }

    openChat() {
        this._supportChatService.showMessengerPanel();
    }
}
