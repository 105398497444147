<div class="m-2 lg:mx-4">
    <ng-container *ngFor="let alert of alerts">
        <div class="flex flex-col space-y-4" *ngIf="!alert.isHidden">
            <mat-card
                class="self-center p-4 mb-2 rounded-2xl w-full {{ alert.type }}-card mat-elevation-z2 cursor-pointer"
                (click)="_alertService.execute(alert)"
            >
                <div class="flex flex-row space-x-4 {{ alert.type }}-card-dark">
                    <mat-icon aria-hidden="true" class="icon">{{ alert.type }}</mat-icon>

                    <div class="flex-col flex-grow space-y-2">
                        <mat-card-title class="{{ alert.type }}-card-dark font-semibold">{{
                            alert.title
                        }}</mat-card-title>
                        <mat-card-subtitle class="{{ alert.type }}-card-dark">{{
                            alert.description
                        }}</mat-card-subtitle>
                    </div>
                    <div class="flex space-x-4 justify-self-end">
                        <button
                            *ngIf="alert.showButton"
                            mat-flat-button
                            (click)="_alertService.execute(alert)"
                            [color]="alert.buttonColor"
                        >
                            {{ alert.buttonLabel }}
                        </button>

                        <button
                            class="justify-self-end"
                            mat-icon-button
                            *ngIf="alert.closeable"
                            (click)="_alertService.dismiss(alert)"
                            title="close-button"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
    </ng-container>
</div>
