import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { AppSessionService } from "@shared/session/app-session.service";
import { TokenService } from "abp-ng2-module";
import { keys } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";

@Component({
    selector: "blocked-dialog",
    templateUrl: "./blocked-dialog.component.html",
    styleUrls: ["./blocked-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SubscriptionBlockedDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    public url: SafeResourceUrl;

    constructor(
        private _tokenService: TokenService,
        private _changeDetector: ChangeDetectorRef,
        private _sessionService: AppSessionService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {
        window.addEventListener("message", (event) => {
            let { sender, action } = event.data;
            if (sender !== "vsubscription") {
                return;
            }
            if (action === "cancelledSubscription") {
                this._sessionService.checkTrial();
            }
        });
    }

    ngOnInit() {
        this.route.url.pipe(takeUntil(this._unsubscribeAll)).subscribe((a) => {
            let url = `${environment.vadmin_url}/subscription/blocked?`;
            let params = ["layout=empty", `token=${this._tokenService.getToken()}`];
            if (this.route.snapshot.queryParams) {
                for (let key of keys(this.route.snapshot.queryParams)) {
                    params.push(`${key}=${this.route.snapshot.queryParams[key]}`);
                }
            }
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url + params.join("&"));
            this._changeDetector.detectChanges();
        });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
