<div class="toolbar-container">
<mat-toolbar class="p-0 w-full" [ngClass]="tenantClasses">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                    (click)="toggleSidebarOpen('main-navbar')" fxHide.gt-md>
                <mat-icon>menu</mat-icon>
            </button>

            <!--<div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>-->

            <div fxLayout="row" fxLayoutAlign="start center" *xcMediaIf="'gt-sm'">
                <div class="logo">
                    <img class="logo-icon" [src]="logoUrl">
                </div>
                <trial-message></trial-message>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *xcMediaIf="'lt-sm'">
                <trial-message></trial-message>
            </div>

            <div class="px-2 px-mat-16">
                <!--<fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>-->
            </div>

        </div>

        <div class="pr-2 space-x-2" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <!-- <button mat-raised-button *xcMediaIf="'gt-sm'" color="accent" class="mr-4 refer-button" (click)="openCanpaign()">
                <mat-icon>attach_money</mat-icon> Indique e ganhe!
            </button> -->
            <!-- <button mat-flat-button color="accent" (click)="createAppointment()">
                <mat-icon svgIcon="add-appointment"></mat-icon>
            </button> -->
            <button mat-button
                *xcMediaIf="'gt-xs'"
                [matMenuTriggerFor]="helpMenu"
                color="primary"
                aria-label="Ajuda"
                matTooltip="Ajuda">
                <mat-icon>help_outline</mat-icon>
                <span class="px-2"> Ajuda </span>
            </button>
            <mat-menu #helpMenu="matMenu">
                <button mat-menu-item (click)="openKb()">
                    <mat-icon>help_outline</mat-icon>
                    <span>Central de Ajuda</span>
                </button>
                <button mat-menu-item (click)="openChat()" *ngIf="supportChatEnabled">
                  <mat-icon>chat</mat-icon>
                  <span>Chat - Fale com a VSaúde</span>
                </button>
            </mat-menu>

            <div *ngIf="!isLocked()">
                <button mat-icon-button color="primary" [matMenuTriggerFor]="notificationMenu" *xcMediaIf="'gt-xs'">
                    <mat-icon [matBadge]="countUnread" [matBadgeHidden]="countUnread === 0" matBadgeColor="warn"
                              matBadgePosition="above after" svgIcon="bell">
                    </mat-icon>
                </button>
            </div>

            <mat-menu class="notifications-menu" #notificationMenu="matMenu" [overlapTrigger]="false">
                <div (click)="$event.stopPropagation()">
                    <div class="w-full bg-primary text-white flex items-center">
                        <div class="flex items-center p-4 w-full space-x-4 justify-between">
                            <span class="text-lg">Novas notificações</span>
                            <button mat-icon-button (click)="navigateToNotificationSettings()">
                                <mat-icon>settings</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="notification-list overflow-hidden" fusePerfectScrollbar *ngIf="notifications.length > 0">
                      <div *ngFor="let notification of notifications">
                        <div class="flex w-full px-4 items-center cursor-pointer hover:bg-gray-50" (click)="openNotification($event, notification)">
                          <mat-icon>{{notification.icon}}</mat-icon>
                          <div class="flex items-center m-4 w-full">
                            <div class="flex flex-col">
                              <span matListItemTitle class="text-base">{{notification.title}}</span>
                              <div matListItemLine class="flex items-center">
                                <span class="text-sm secondary-text">{{notification.subtitle}}</span>
                              </div>
                            </div>
                          </div>
                          <button color="primary" mat-icon-button (click)="markAsRead($event, notification.id)">
                            <mat-icon>check</mat-icon>
                          </button>
                        </div>
                        <div class="border-t border-#E3E3E3"></div>
                      </div>
                    </div>

                    <div *ngIf="notifications.length === 0" class="m-8" fxLayoutAlign="center center" fxLayout="column">
                        <span>{{l("Pages.Notifications.Recent.Empty.Message")}}</span>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="m-2" fxLayout="row" fxLayoutAlign="space-between center">
                        <button mat-button color="primary" (click)="showAllNotifications()">
                            {{l("Pages.Notifications.Action.ShowAll.Title")}}
                        </button>
                        <button mat-button color="primary" (click)="markAllNotificationsAsRead()" *ngIf="notifications.length > 0">
                            <mat-icon class="mat-icon material-icons">check</mat-icon>
                            {{l("Pages.Notifications.Action.MarkAllAsRead.Title")}}
                        </button>
                    </div>

                </div>
            </mat-menu>

            <button mat-button color="primary" [matMenuTriggerFor]="profileMenu" class="navbar-toolbar-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="username mr-3" fxHide fxShow.gt-sm>{{username}}</span>
                    <mat-icon class="s-16" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
                    <img class="w-8 h-8 border-radius-100" [src]="avatarUrl" fxHide fxShow.lt-md>
                </div>
            </button>

            <mat-menu #profileMenu="matMenu" [overlapTrigger]="false">
                <div (click)="$event.stopPropagation()" class="profile-menu-container">
                    <div class="m-4" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <img class="w-16 h-16 border-radius-100" [src]="avatarUrl">
                            <div class="ml-4">
                                <div class="text-bold">{{this.fullName}}</div>
                                <!--<div class="h5 text-bold secondary-text">20 mins. ago</div>-->
                                <div class="mt-2" *ngIf="!isLocked()">
                                    <button mat-button color="accent" routerLink="/account/profile"
                                            routerLinkActive="active" (click)="navigateToProfilePage()"
                                            *ngIf="showItemProfileInfo">{{l("Pages.Landing.Toolbar.Action.Profile.Title")}}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="toolbar-profile-menu-options-list" *ngIf="!isLocked()">
                        <mat-nav-list>
                            <mat-list-item *xcMediaIf="'xs'" [matMenuTriggerFor]="notificationMenu">
                                <div class="w-full h-full pl-4" fxLayout="row" fxLayoutAlign="start center">
                                    <span>Notificações</span>
                                </div>
                            </mat-list-item>
                            <div class="pl-4" *xcMediaIf="'xs'">
                                <mat-divider></mat-divider>
                            </div>

                            <div *ngIf="chatEnabled">
                                <mat-list-item *xcMediaIf="'xs'" (click)="toggleSidebarOpen('chatPanel')">
                                    <div class="w-full h-full pl-4" fxLayout="row" fxLayoutAlign="start center">
                                        <span>Chat</span>
                                    </div>
                                </mat-list-item>
                                <div class="pl-4" *xcMediaIf="'xs'">
                                    <mat-divider></mat-divider>
                                </div>
                            </div>

                            <div *ngIf="supportChatEnabled">
                              <mat-list-item *xcMediaIf="'xs'">
                                <button mat-menu-item (click)="openChat()" *ngIf="supportChatEnabled">
                                  <span>Suporte</span>
                                </button>
                              </mat-list-item>
                              <div class="pl-4" *xcMediaIf="'xs'">
                                  <mat-divider></mat-divider>
                              </div>
                            </div>

                            <mat-list-item>
                                <div class="w-full h-full pl-4" fxLayout="row" fxLayoutAlign="start center">
                                    <span>Conta: {{tenantId}}</span>
                                </div>
                            </mat-list-item>
                            <mat-list-item (click)="openLegalDocuments()">
                                <div class="w-full h-full pl-4" fxLayout="row" fxLayoutAlign="start center">
                                    <span>Documentos legais</span>
                                </div>
                            </mat-list-item>
                        </mat-nav-list>
                    </div>

                    <div class="pl-4 mb-4">
                        <mat-divider></mat-divider>
                    </div>

                    <div class="ml-1 mt-3 mb-1 mr-2" fxLayout="row" fxLayoutAlign="end center">
                        <button mat-button color="warn" (click)="logout()">
                            <mat-icon class="mat-icon material-icons">exit_to_app</mat-icon>
                            {{l("Logout")}}
                        </button>
                    </div>

                </div>
            </mat-menu>

            <!--<div class="toolbar-separator"></div>-->

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-4" [src]="'assets/images/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

                <button mat-menu-item [routerLink]="'/components/multi-language'">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>Learn more</span>
                    </div>
                </button>

            </mat-menu>

            <button mat-icon-button fxHide.gt-md class="navbar-toggle-button"
                    (click)="toggleSidebarOpen('chatPanel')" *ngIf="chatEnabled">
                <mat-icon class="s-32" svgIcon="chat"></mat-icon>
            </button>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                    (click)="toggleSidebarOpen('main-navbar')" fxHide.gt-md>
                <mat-icon class="">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
</div>
